import React from "react";
import getCheckoutUrl from "../config/checkoutUrl";
import { BadgeCheck, Check } from "lucide-react";

const INDIVIDUAL = [
  "1 conexão de WhatsApp",
  "Segmentação de Mensagens",
  "Compreensão Sequencial",
  "Conversas ilimitadas",
  "Acesso ao prompt mágico",
  "24h de conexão por dia",
  "Interpretação de áudio",
  "500 Leads de conversão",
  "Acesso a API de integracão (em breve)",
  "Disparo de mensagens (em breve)",
];

const BUSINESS = [
  "Até 3 conexões de WhatsApp",
  "Interpretação de áudio",
  "Leads de conversão ilimitados",
  "Acesso a API de integracão (em breve)",
  "Disparo de mensagens (em breve)",
];

interface PricingProps {
  redirectToCheckout?: boolean;
}

const Pricing = ({ redirectToCheckout }: PricingProps) => {
  return (
    <>
      <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
        <h2 className="text-2xl font-bold md:text-4xl md:leading-tight">
          Nosso plano <span className="text-primary">Mensal</span>
        </h2>
        <BadgeCheck size={52} className="text-green  mx-auto mt-12" />

        {redirectToCheckout ? (
          <p className="mt-2 text-xl">
            <span className="font-bold ">
              {" "}
              7 dias de garantia incondicional
            </span>{" "}
            <br /> e cancele quando quiser
          </p>
        ) : (
          <p className="mt-2 text-xl">
            <span className="font-bold ">
              {" "}
              7 dias de teste totalmente gratuito
            </span>{" "}
            <br /> Não fazemos qualquer tipo de cobrança antecipada
          </p>
        )}
      </div>

      <div className="mt-12">
        <div className="flex flex-col max-w-2xl mx-auto border-2 border-primary border-4 bg-background text-center rounded-xl p-8">
          {/* <p className="mb-3">
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs text-white uppercase font-semibold bg-primary">
              Oferta especial
            </span>
          </p> */}
          <h4 className="font-bold text-3xl mt-4">Entre na lista de espera</h4>
          {/* <span className="mt-5 font-regular text-md line-through">
            de R$ 79,90
          </span> */}

          {/* <span className="font-regular text-2xl mt-4">
            R$ <span className="font-bold text-4xl">xxx</span>/atendente
          </span> */}
          {/* <span className="mb-5 mt-1 font-regular text-xs">
            no primeiro mês
          </span> */}

          <ul className="mt-7 space-y-4 text-sm">
            {INDIVIDUAL.map((feature) => (
              <li key={feature} className="flex text-left items-center gap-2">
                <p className="text-green">
                  <Check size={24} />
                </p>
                <span className="text-gray-800 dark:text-gray-400 text-xl">
                  {feature}
                </span>
              </li>
            ))}
          </ul>

          <a
            className={`mt-8 py-4 px-4 inline-flex text-xl justify-center items-center gap-x-2 text-sm font-semibold rounded-md border  text-white hover:scale-105 transition-all ${redirectToCheckout ? "border-green bg-green" : "border-primary bg-primary"}`}
            href={getCheckoutUrl(redirectToCheckout)}
            target="_blank"
            rel="noreferrer"
          >
            {redirectToCheckout ? "Grupo do Whatsapp" : "Experimente agora"}
          </a>
        </div>

        {/* <div className="flex flex-col col-span-4 border-4 border-primary text-center bg-background rounded-xl p-8">
        <p className="mb-3">
            <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs text-white uppercase font-semibold bg-primary">
              Mais Popular
            </span>
          </p>

          <h4 className="font-bold text-3xl mt-4">Plano PRO</h4>

          <span className="mt-5 font-regular text-md line-through">
            de R$ 99,90
          </span>

          <span className="font-regular text-2xl">
            por R$ <span className="font-bold text-4xl">79,90</span>
          </span>
          <span className="mb-5 mt-1 font-regular text-xs">
            no primeiro mês
          </span>

          <p className="mt-6 text-xl text-left font-bold text-green">
            Tudo do plano individual mais:
          </p>

          <ul className="mt-4 space-y-4 text-sm">
            {BUSINESS.map((feature) => (
              <li
                key={feature}
                className="flex text-left font-medium items-center gap-2"
              >
                <Check size={22} className="text-green" />
                <span className="text-lg">{feature}</span>
              </li>
            ))}
          </ul>

          <a
            className="mt-8 py-4 px-4 inline-flex text-xl justify-center items-center gap-x-2 text-sm font-semibold rounded-md border border-primary bg-primary text-white hover:scale-105 transition-all"
            href={getCheckoutUrl(true)}
            target="_blank" 
            rel="noreferrer"
          >
            Assinar agora
          </a>
        </div> */}
      </div>
    </>
  );
};

export default Pricing;

import React from "react";
import Logo1 from "../assets/logo.svg";
import Container from "../components/Container";
import { groupUrl } from "../config/checkoutUrl";

const LINKS = [
  { label: "Termos e Condições", link: "/terms" },
  { label: "Planos", link: "/pricing" },
  {
    label: "Acessar plataforma",
    link: "https://app.zatten.com/login",
    isExternal: true,
  },
  {
    label: "Grupo do WhatsApp",
    link: groupUrl,
    isExternal: true,
  },
  {
    label: "Instagram",
    link: "https://www.instagram.com/zatten_/",
    isExternal: true,
  },
];

const Footer: React.FC = () => {
  return (
    <Container backgroundCard>
      <div className="flex justify-center items-center">
        <img className="w-24 h-10" src={Logo1} alt="" />
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center gap-8 mt-8">
        {LINKS.map((link) => (
          <a
            key={link.label}
            href={link.link}
            target={link.isExternal ? "_blank" : "_self"}
            rel="noreferrer"
            className="text-sm font-semibold text-text"
          >
            {link.label}
          </a>
        ))}
      </div>
    </Container>
  );
};

export default Footer;
